$blue: #007bff;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #d63384;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #198754;
$teal: #20c997;
$cyan: #0dcaf0;
$white: #fff;
$gray: #6c757d;
$gray-dark: #343a40;
$primary: #0066b2;
$secondary: #6c757d;
$success: #00708c;
$info: #0dcaf0;
$warning: #e5601f;
$danger: #97002e;
$light: #f8f9fa;
$dark: #212529;

// primary blue design palette

// primary gray design palette

// secondary colors

$custom-dark: #000000;
$custom-dark-purple: #3a0d36;
$custom-light-purple: #751569;
$custom-light-gray: #f6ebf4;
$custom-dark-red: #58001c;
$custom-brown: #711b00;
$custom-dark-blue: #003653;
$custom-light-gray-second: #e4f4fa;
$custom-yellow: #e3be50;
$custom-dark-green: #444b0d;
$custom-medium-green: #b2cc34;
$custom-dark-blue-second: #003653;
$review-gray: #6b6d6f;
$custom-red-normal: #97002e;

:root {
  & body {
    font-family: "GothamNarrowBook", Arial, sans-serif;
  }

  --blue: #{$blue};
  --indigo: #{$indigo};
  --purple: #{$purple};
  --pink: #{$pink};
  --red: #{$red};
  --orange: #{$orange};
  --yellow: #{$yellow};
  --green: #{$green};
  --teal: #{$teal};
  --cyan: #{$cyan};
  --white: #{$white};
  --gray: #{$gray};
  --gray-dark: #{$gray-dark};
  --primary: #{$primary};
  --secondary: #{$secondary};
  --success: #{$success};
  --info: #{$info};
  --warning: #{$warning};
  --danger: #{$danger};
  --light: #{$light};
  --dark: #{$dark};
  --custom-dark: #{$custom-dark};
  --custom-dark-purple: #{$custom-dark-purple};
  --custom-light-purple: #{$custom-light-purple};
  --custom-light-gray: #{$custom-light-gray};
  --custom-dark-red: #{$custom-dark-red};
  --custom-brown: #{$custom-brown};
  --custom-dark-blue: #{$custom-dark-blue};
  --custom-light-gray-second: #{$custom-light-gray-second};
  --custom-yellow: #{$custom-yellow};
  --custom-dark-green: #{$custom-dark-green};
  --custom-medium-green: #{$custom-medium-green};
  --custom-dark-blue-second: #{$custom-dark-blue-second};
  --review-gray: #{$review-gray};

  --font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
  --gradient: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0)
  );
}

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "danger": $danger,
  "draft": $primary,
  "inecreview": $warning,
  "inipreview": $custom-red-normal,
  "intechnicalreview": $custom-yellow,
  "insmereview": $review-gray,
  "inlegalreview": $danger,
  "llreview": $cyan,
  "publish": $custom-medium-green,
  "reviewcompleted": $green,
  "rejected": $danger,
);
