@mixin circle-status {
  width: 4px;
  height: 4px;
  padding: 4px;
  margin-right: 3px;
  border-radius: 50px;
  line-height: 2px;
  display: inline-block;
}

@mixin box-shadow {
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin status-color($property) {
  @if & {
    &.draft {
      #{$property}: $primary;
      @include circle-status;
    }
    &.review {
      #{$property}: #003653;
      @include circle-status;
    }
    &.inecreview {
      #{$property}: #003653;
      @include circle-status;
    }

    &.inipreview {
      #{$property}: $custom-red-normal;
      @include circle-status;
    }

    &.intechnicalreview {
      #{$property}: $custom-yellow;
      @include circle-status;
    }

    &.inlegalreview {
      #{$property}: $custom-dark-red;
      @include circle-status;
    }

    &.insmereview {
      #{$property}: #0b2d71;
      @include circle-status;
    }

    &.llreview {
      #{$property}: $warning;
      @include circle-status;
    }

    &.publish {
      #{$property}: $custom-medium-green;
      @include circle-status;
    }
    &.approved {
      #{$property}: $custom-medium-green;
      @include circle-status;
    }
    &.rejected {
      #{$property}: $danger;
      @include circle-status;
    }

    &.reviewcompleted {
      #{$property}: $primary;
      @include circle-status;
    }
    &.inipecreview {
      #{$property}: $custom-red-normal;
      @include circle-status;
    }
  } @else {
    .draft {
      #{$property}: $primary;
      @include circle-status;
    }

    .review {
      #{$property}: #003653;
      @include circle-status;
    }

    .inecreview {
      #{$property}: #003653;
      @include circle-status;
    }

    .inipreview {
      #{$property}: $custom-red-normal;
      @include circle-status;
    }

    .intechnicalreview {
      #{$property}: $custom-yellow;
      @include circle-status;
    }

    .inlegalreview {
      #{$property}: $custom-dark-red;
      @include circle-status;
    }

    .insmereview {
      #{$property}: #0b2d71;
      @include circle-status;
    }

    .llreview {
      #{$property}: $warning;
      @include circle-status;
    }

    .publish {
      #{$property}: $custom-medium-green;
      @include circle-status;
    }

    .approved {
      #{$property}: $custom-medium-green;
      @include circle-status;
    }

    .rejected {
      #{$property}: $danger;
      @include circle-status;
    }

    .reviewcompleted {
      #{$property}: $primary;
      @include circle-status;
    }

    .inipecreview {
      #{$property}: $custom-red-normal;
      @include circle-status;
    }
  }
  
}
