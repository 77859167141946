$cvx-light-blue: #009dd9;
$cvx-blue: #0066b2;
$cvx-dark-blue: #0b2d71;

$cvx-light-teal: #00b2bd;
$cvx-teal: #00708c;
$cvx-dark-teal: #003653;

$cvx-light-green: #b2cc34;
$cvx-green: #769231;
$cvx-dark-green: #444b0d;

$cvx-light-orange: #faab18;
$cvx-orange: #e5601f;
$cvx-dark-orange: #711b00;

$cvx-light-red: #e21836;
$cvx-red: #97002e;
$cvx-dark-red: #58001c;

$cvx-light-purple: #ba3093;
$cvx-purple: #751269;
$cvx-dark-purple: #3a0d36;

$cvx-light-gray: #dbdcdd;
$cvx-gray: #8c8f93;
$cvx-dark-gray: #6b6d6f;
$cvx-background-gray: #ededee;

$cvx-highlighted: #e4f4fa;
$cvx-black: #000000;
$cvx-white: #ffffff;

$cvx-font-family: 'Gotham', Arial, sans-serif;
$cvx-font-family-bold: 'GothamBold', Arial, sans-serif;
$cvx-style-border: 1px solid #ededee;
$color-drop-shadow: rgba(0, 0, 0, 0.16);
$separator-color: #cccccc;
$shadow-color: rgba(0, 0, 0, 0.2);

// z-index order, register any z-index higher than 1

$z-index-deliverable-manager: 2;
$z-index-risk-assessment-focus-area: 2;
$z-index-navigation-tab-strip: 3;
$z-index-fullscreen-wrapper: 5;
$z-index-spinner-overlay: 100000;
