main.container section .row [class^="col-"],
main.container section .row [class*=" col-"] {
  margin-right: 0px;
}

//material
.mat-icon {
  vertical-align: middle;
}

.mat-mdc-table {
  box-shadow: none;
  & th.mat-mdc-header-cell,
  td.mat-mdc-cell,
  td.mat-mdc-footer-cell {
    border-bottom: none !important;
  }
}



.mat-mdc-cell,
.mat-mdc-footer-cell {
  color: #6e7074;
  font-weight: bold;
}

//

//next page
.button:after,
.btn:after {
  content: "";
  display: inline-block;
  font-size: 16px;
  padding: 0 0 0 5px;
}

.button:hover,
.btn:hover {
  opacity: 80%;
  border-bottom-style: none;
}

main.container-fluid {
  background: #ffffff;
  padding: 15px 15px 30px 15px;
  box-shadow: 0px 3px 6px #00000029;
}

@media (min-width: 992px) {
  main.container {
    max-width: 100%;
    min-height: 600px;
  }
}

header,
nav#site-specific-nav,
main,
footer {
  max-width: 100%;
}

table {
  width: 100% !important;
}

//

// bootstrap
.btn {
  border-radius: 0 !important;
}

.toast {
  max-width: 100% !important;
}
//

.mat-mdc-dialog-container {
  padding-left: 12px !important;
  padding-right: 12px !important;
  overflow: unset !important;
}


.mat-dialog{
  min-width: fit-content;
  min-height: fit-content;
  padding: 10px;
}
// th.mat-mdc-header-cell {
//   font-weight: 700;
//   color: #000000;
//   font-size: 14px;
// }

.mat-mdc-tooltip {
  font-size: 14px !important;
}

//  .mat-select-arrow-wrapper {
//     background-image: url("src/assets/icons/13.svg");
//     background-repeat: no-repeat;
//     background-position: center;
// }


/*Styling mat bar in lessons list component*/
.mat-ink-bar {
  height: 3px !important;
}

.mat-mdc-dialog-container{

  padding: 10px;
}