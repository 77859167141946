.left-align {
  float: left;
}

.right-align {
  float: right;
}

.text-left-align {
  text-align: left !important;
}

.text-right-align {
  text-align: right !important;
}

.color-label {
  color: #0583c0;
}
.black-label-normal {
}
.black-label-bold {
}

button {
  min-width: 100px;
  border: 2px solid #0066b2;
}

.button-blue {
  background-color: #0066b2;
  color: #ffffff;
}

.button-white {
  background-color: #ffffff;
  color: #0066b2;
}

input {
  height: 40px;
  position: relative;
  width: 98%;
  border: 1px solid #cccccc;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  color: #0583c0;
  padding: 10px !important;
}

textarea {
  height: 78px;
  position: relative;
  width: 98%;
  border: 1px solid #cccccc;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  color: #0583c0;
  padding: 10px !important;
  max-width: 96% !important;
}

.mat-select-panel-class {
  background-color: #ffffff;
  font-size: 10px;
  color: #0583c0;
  border: 1px solid #cccccc;
}


.mat-form-field-infix {
  padding: 0px !important;
  border-top: 0em solid transparent !important;
}

.mat-mdc-form-field {
  line-height: 2.5 !important;
}

// .mat-mdc-text-field-wrapper {
//   box-shadow: 0px 3px 6px #00000029;
// }
.mat-mdc-select {
  font-family: "GothamBold",Arial,sans-serif;
  color: #0066b2;
}
.mat-form-field-infix {
  font-family: "GothamBold",Arial,sans-serif;
  color: #0066b2;
}

.mat-form-field-label {
  font-family: "GothamBold",Arial,sans-serif;
  color: #0066b2;
}

.mat-mdc-form-field .mat-mdc-floating-label.mdc-floating-label {
  font-family: "GothamBold",Arial,sans-serif;
  color: #0066b2;
}


.mat-select-style {
  height: 40px;
  position: relative;
  width: 98%;
  border: 1px solid #cccccc;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  color: #0583c0;
}

.mat-form-field-underline {
  background-color: transparent !important;
}

.mat-form-field-label {
  padding-bottom: 10px !important;
  color: #0583c0 !important;
  padding-left: 10px !important;
  margin-top: -7px !important;
}


label.mat-form-field-label span {
  visibility: hidden;
}

label.mat-form-field-empty span {
  visibility: visible;
}

.paddingmarginTopBottom0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.addMinWidth {
  min-width: 70%;
}

.paddingmarginTop30 {
  padding-top: 30px !important;
  padding-bottom: 0px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.paddingmarginleftright0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.paddingmarginleft0 {
  padding-left: 0px !important;
  margin-left: 0px !important;
}
.paddingmarginleft7 {
  padding-left: 7px !important;
  margin-left: 7px !important;
}
.paddingmarginleftright_5_0 {
  padding-left: 5px !important;
  padding-right: 0px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.paddingtop10 {
  padding-top: 10px !important;
}

.paddingtop30 {
  padding-top: 30px !important;
}

.paddingbottom30 {
  padding-bottom: 30px !important;
}

.paddingbottom20 {
  padding-bottom: 20px !important;
}

.paddingbottom10 {
  padding-bottom: 10px !important;
}

.paddingleft10 {
  padding-left: 10px !important;
}
.paddingleft30 {
  padding-left: 30px !important;
}
.paddingright30 {
  padding-right: 30px !important;
}
.paddingright10 {
  padding-right: 10px !important;
}
.paddingright20 {
  padding-right: 20px;
}
.mouseCursor {
  cursor: pointer;
}

/*.required:after {
    content: " *";
    color: red;
}*/

.required:after {
  content: " (required)";
  color: #0066b2;
}

.mat-select-value-text span {
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  color: #0583c0;
  padding-left: 10px !important;
  padding-right: 10px !important;
  font-weight: bold;
}

.mat-select-arrow {
  color: #0583c0 !important;
}

input.ng-invalid.error {
  border: 1px solid red !important;
}

textarea.ng-invalid.error {
  border: 1px solid red !important;
}

p-autocomplete.ng-invalid.error span.p-autocomplete input.p-autocomplete-input {
  border: 1px solid red !important;
}

mat-select.ng-invalid.error {
  border: 1px solid red !important;
}

.mat-mdc-menu-panel {
  background-color: #0b2d71 !important;
}

.submenu {
  position: relative;
}

.submenu:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid black;
  left: 20px;
  bottom: 110%;
}

.star {
  cursor: pointer;
  color: #0066b2;
}

.wordwrap {
  white-space: pre-wrap; /* CSS3 */
  white-space: -moz-pre-wrap; /* Firefox */
  white-space: -pre-wrap; /* Opera <7 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* IE */
  line-break: anywhere;
}

.borderNone {
  box-shadow: none !important;
  border: none !important;
}
.backgroundcolorgrey {
  background-color: #f3f3f3 !important;
}
