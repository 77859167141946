/* You can add global styles to this file, and also import other style files */

@import "./assets/css/themes/custom.scss";
@import "./assets/css/overrides.scss";
@import "./assets/css/page_controls.scss";
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "./assets/css/mixins.scss";
@import "angular-text-input-highlight/text-input-highlight.css";
@import "./assets/css/variables.scss";
@import "./app/styles/variables";

.searchCard {
  padding-top: 1%;
  padding-bottom: 1%;
  //border-style: solid;
  border-color: #009dd9;
  border-width: 0 1px 1px 1px;
  height: fit-content;
  border-radius: 0 0 10px 10px;
}

.sectionTitle {
  color: #009dd9;
}

.searchOptions {
  padding-top: 1%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.sticky {
  position: fixed;
  top: 0;
  overflow: hidden;
  z-index: 10;
  width: 95%;
  padding-right: 20px !important;
}

.sideFilter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #f0fcff !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.checkLabel {
  font-size: x-small !important;
  color: #009dd9;
  padding-left: 5px;
}

.cesSubTitle {
  color: #0b2d71;
  font-weight: bolder;
  font-size: xx-small;
}

.cesText {
  color: #0b2d71;
  font-size: xx-small;
}

.sideWidth {
  width: 95% !important;
  padding: 5px !important;
  padding-bottom: 5px !important;
  margin-bottom: 5px !important;
}

.cesBtn {
  border-style: solid !important;
  border-width: 2px !important;
  border-color: #009dd9 !important;
  padding: 5px !important;
  border-radius: 3px;
  font-weight: bolder;
  cursor: pointer;
}

.whiteBtn {
  background-color: white !important;
  color: #009dd9 !important;
}

.blueBtn {
  background-color: #009dd9 !important;
  color: white !important;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-primary {
  display: flex;
  align-items: center;
  justify-content: center;
}

.linkBtn {
  margin-top: auto;
  width: fit-content;
  color: #009dd9 !important;
  font-weight: bolder;
  font-size: smaller;
  cursor: pointer;
}

.rowSeparator {
  border-style: solid;
  border-width: 0 0 1px 0;
  padding-bottom: 5px;
}

[type="checkbox"] {
  vertical-align: middle;
}

.ngb-toasts {
  width: 99%;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

main.container section .row .col {
  box-shadow: none;
}

.spinner{
  align-items: center;
  justify-content: center;
}

// status classes
@include status-color(color);

.circle {
  @include status-color(background-color);
}


.textDetail {
  color: #8c8f93;
  font-size: 14px;
  padding-bottom: 1em;
}



.input-text {
  height: 50px;
  font-size: 20px;
  color: #000000;
  font-weight: bold;
}

.title-label {
  margin-bottom: 0;
  font-size: 15px;
  letter-spacing: var(--unnamed-character-spacing-0);
  padding-bottom: 3px;
  font-weight: bold !important;
}

.title-label-gray {
  color: gray !important;
}

table tbody tr:nth-child(odd) td {
  background-color: lightgray;
  color: black;
}

  
.mat-mdc-snack-bar-container.error {
  --mdc-snackbar-container-color: #97002E;
  --mat-mdc-snack-bar-button-color: #fff;
  --mdc-snackbar-supporting-text-color: #fff;
}

.mat-mdc-snack-bar-container.warning {
  --mdc-snackbar-container-color: #E5601F;
  --mat-mdc-snack-bar-button-color: #fff;
  --mdc-snackbar-supporting-text-color: #fff;
}

.mat-mdc-snack-bar-container.success {
  --mdc-snackbar-container-color: #444B0D !important;
  --mat-mdc-snack-bar-button-color: #fff;
  --mdc-snackbar-supporting-text-color: #fff;
}

#nextButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $cvx-blue;
  width: 10rem;
  height: 2.5rem;
  color: $cvx-white !important;
  font-family: $cvx-font-family-bold;
  padding-left: 1rem;

  &:hover {
      cursor: pointer;
      background-color: $cvx-dark-blue;
      box-shadow: 0 3px 6px $shadow-color;
  }
}


#submitButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $cvx-blue;
  width: 6.25rem;
  height: 2.5rem;
  color: $cvx-white !important;
  font-family: $cvx-font-family-bold;

  &:hover {
      cursor: pointer;
      background-color: $cvx-dark-blue;
      box-shadow: 0 3px 6px $shadow-color;
  }
}


#clearButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $cvx-light-gray;
  width: 8rem;
  height: 2.5rem;
  color: $cvx-black !important;
  font-family: $cvx-font-family-bold;

  &:hover {
      cursor: pointer;
      background-color: $cvx-dark-gray;
      box-shadow: 0 3px 6px $shadow-color;
      color: $cvx-white !important;
  }
}


#saveButton {
  text-align: right;
  min-width: 15% !important;
  margin: 0rem 1rem 0rem 0rem;
  padding: 0.5rem 0.5rem;
  font-family: $cvx-font-family-bold;

  &:hover {
      cursor: pointer;
      color: $cvx-dark-blue;
      transform: scale(1.03); 
      transition: box-shadow 0.3s ease, transform 0.3s ease;
  }
}

#validateButton {
  background-color: $cvx-blue;
  width: 12rem;
  height: 2.5rem;
  color: $cvx-white !important;
  font-family: $cvx-font-family-bold;

  &:hover {
      cursor: pointer;
      background-color: $cvx-dark-blue;
      box-shadow: 0 3px 6px $shadow-color;
  }
}

#plbButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $cvx-blue;
  width: 12rem;
  height: 2.5rem;
  color: $cvx-white !important;
  font-family: $cvx-font-family-bold;

  &:hover {
      cursor: pointer;
      background-color: $cvx-dark-blue;
      box-shadow: 0 3px 6px $shadow-color;
  }

}

#backButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $cvx-red;
  width: 6.25rem;
  height: 2.5rem;
  color: $cvx-white !important;
  border: 1px solid $cvx-dark-red;
  font-family: $cvx-font-family-bold;

  &:hover {
      cursor: pointer;
      background-color: $cvx-dark-red;
      box-shadow: 0 3px 6px $shadow-color;
  }
}

#lessonQualityButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $cvx-white;
  color: $cvx-green;
  width: 12rem;
  height: 2.5rem;
  border: 1px solid $cvx-green;
  font-family: $cvx-font-family-bold;

  &:hover {
      cursor: pointer;
      background-color: $cvx-green;
      color: $cvx-white;
      box-shadow: 0 3px 6px $shadow-color;
  }
}

#cancelButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $cvx-white;
  color: $cvx-blue;
  width: 6.25rem;
  height: 2.5rem;
  border: 1px solid $cvx-blue;
  font-family: $cvx-font-family-bold;

  &:hover {
      cursor: pointer;
      background-color: $cvx-blue;
      color: $cvx-white;
      box-shadow: 0 3px 6px $shadow-color;
  }
}

#deleteButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $cvx-red;
  color: $cvx-white;
  border: 1px solid $cvx-dark-red;
  font-family: $cvx-font-family-bold;

  &:hover {
      cursor: pointer;
      background-color: $cvx-dark-red;
      color: $cvx-white;
      box-shadow: 0 3px 6px $shadow-color;
  }

}

#validateButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $cvx-blue;
  width: 12rem;
  height: 2.5rem;
  color: $cvx-white !important;

  &:hover {
      cursor: pointer;
      background-color: $cvx-dark-blue;
      box-shadow: 0 3px 6px $shadow-color;
  }
}

#rejectButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $cvx-red;
  width: 6.25rem;
  height: 2.5rem;
  color: $cvx-white !important;
  border: 1px solid $cvx-dark-red;
  font-family: $cvx-font-family-bold;

  &:hover {
      cursor: pointer;
      background-color: $cvx-dark-red;
      box-shadow: 0 3px 6px $shadow-color;
  }
}


#approveButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $cvx-green;
  width: 6.25rem;
  height: 2.5rem;
  color: $cvx-white !important;
  border: 1px solid $cvx-dark-green;
  font-family: $cvx-font-family-bold;

  &:hover {
      cursor: pointer;
      background-color: $cvx-dark-green;
      box-shadow: 0 3px 6px $shadow-color;
  }
}

#previousButton {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $cvx-red;
    width: 10rem;
    height: 2.5rem;
    color: $cvx-white !important;
    border: 1px solid $cvx-dark-red;
    font-family: $cvx-font-family-bold;
    margin-right: 1rem;
  
    &:hover {
        cursor: pointer;
        background-color: $cvx-red;
        box-shadow: 0 3px 6px $shadow-color;
    }
}